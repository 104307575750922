<template>
  <p class="title-p" >投诉建议</p>
  <div class="ctent" style="position: relative" v-show="!dialogVisible">
    <div class="title-tool department-tool">

      <div style="display: flex;">
        <div class="tool-item">
          <div class="tool-label">
            搜索：
          </div>
          <el-input v-model="search" placeholder="输入用户昵称搜索" style="width: 100%;"/>
        </div>
		
		<div class="tool-item">
		  <div class="tool-label">
		    投诉类型：
		  </div>
		  <el-select v-model="toolType" placeholder="选择投诉类型">
			    <el-option label="全部" value="" ></el-option>
		        <el-option :label="item.name" :value="item.name" v-for="(item,index) in proposalTypeList" :key="index"></el-option>
		</el-select>
		</div>
		
		<div class="tool-item">
		  <div class="tool-label">
		    是否回复：
		  </div>
		  <el-select v-model="toolState" placeholder="选择是否回复">
			    <el-option label="全部" value=""></el-option>
		        <el-option label="未回复" value="'0'"></el-option>
		        <el-option label="已回复" value="'1'"></el-option>
		</el-select>
		</div>


        <div class="tool-item" style="color: #ffffff;">
          <el-button color="#2968D4" type="primary" @click="selectBtn">查 询</el-button>
          <el-button @click="resetBtn">重置</el-button>
        </div>
      </div>
	  
		  <el-button @click="dialogFormVisible = true" type="warning" >编辑投诉类型</el-button>

    </div>
    <div class="department-table">
      <el-table :data="userTableData" style="width: 100%" :row-style="{height:'65px'}">
        <el-table-column prop="type" label="投诉类型" align="center"/>
		<el-table-column prop="nick_name" label="用户昵称" align="center" show-overflow-tooltip/>
        <el-table-column prop="state" label="状态" align="center">
		<template #default="scope">
			<el-tag type="success" v-if="scope.row.state == 1">已回复</el-tag>
			<el-tag type="danger" v-else>未回复</el-tag>
		</template>
		</el-table-column>
        <el-table-column prop="add_time" label="发布时间" align="center"/>
        <el-table-column label="操作">
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <button class="el-button el-button--text el-button--small" type="button"
                      @click="showDepartment(scope.row)" >
                <span style="color: #409eff;">查看</span>
              </button>
             <!-- <el-popconfirm title="确定要删除吗?" @confirm="delDepartment(scope.row)">
                <template #reference>
                  <button class="el-button el-button--text el-button--small" type="button">
                    <span style="color: #f56c6c;">删除</span>
                  </button>
                </template>
              </el-popconfirm> -->
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="job_number" label="工号"  align="center"/> -->
      </el-table>
    </div>
    <!-- 分页 -->
    <div class="pagination-box">
      <el-pagination
          v-model:currentPage="nowPage"
          v-model:page-size="pageLimit"
          layout="total,prev, pager, next, jumper"
          :total="tableTotal"
          @current-change="handleCurrentChange"
      />
    </div>
  </div>

  <div class="ctent" style="position: relative;padding-top: 50px;height: auto;" v-show="dialogVisible">
	  <div style="position: absolute;left: 10px;top: 10px">
	    <el-button type="primary" plain @click="backForm">返回</el-button>
	  </div>
	  <el-form label-width="120px">
		  <el-form-item label="投诉类型">
		      <el-input v-model="nowDepartment.type" disabled></el-input>
		  </el-form-item>
		  <el-form-item label="上传时间">
		      <el-input v-model="nowDepartment.add_time" disabled></el-input>
		  </el-form-item>
		  <el-form-item label="投诉人昵称">
		      <el-input v-model="nowDepartment.nick_name" disabled></el-input>
		  </el-form-item>
		  <el-form-item label="投诉内容">
		      <el-input v-model="nowDepartment.content" disabled type="textarea"></el-input>
		  </el-form-item>
	  </el-form>
	  
	  <div style="text-align: right;">
	    <el-button type="primary" plain @click="replyForm">回 复</el-button>
	  </div>
	  
	  <!-- 回复
	   -->
	   <el-drawer
	     title="投诉建议回复"
	     v-model="dialog"
	     direction="rtl"
	     custom-class="demo-drawer"
		 :close-on-click-modal="false"
		 size='45%'
		 @closed="dialogClosed"
	     ref="drawer"
	     >
	     <div class="demo-drawer__content">
	       <el-form >
	         <el-form-item label="回复内容" :label-width="80">
	           <el-input
	               v-model="reply_content"
	               :autosize="{ minRows: 24 }"
	               type="textarea"
	               placeholder="请输入回复内容"
	             />
	         </el-form-item>
	       </el-form>
	       <div class="demo-drawer__footer">
	         <el-button @click="dialog = false">取 消</el-button>
	         <el-button type="primary" @click="replySubmit" :loading="loading">{{ loading ? '提交中 ...' : '确 定' }}</el-button>
	       </div>
	     </div>
	   </el-drawer>

  </div>
  
  
  <!-- 编辑投诉类型弹出
   -->
   <el-dialog v-model="dialogFormVisible" title="编辑投诉类型">
	   
	   <div style="text-align: left;">
		   <el-tag
		       v-for="(tag,index) in proposalTypeList"
		       :key="index"
		       class="mx-1"
		       closable
		       :disable-transitions="false"
		       @close="handleTagClose(tag)"
		     >
		       {{ tag.name }}
		     </el-tag>
			 
			 <el-input
			     v-if="inputVisible"
			     ref="InputRef"
			     v-model="tagInputValue"
			     class="ml-1 w-20"
			     size="small"
			     @keyup.enter="handleInputConfirm"
			     @blur="handleInputConfirm"
			   />
			   <el-button v-else class="button-new-tag bl-1" size="small" @click="showInput">
			     + 添加类型
			   </el-button>
			 
	   </div>
       
       <template #footer>
         <span class="dialog-footer">
           <el-button @click="dialogFormVisible = false">关闭</el-button>
         </span>
       </template>
     </el-dialog>



  <!--11-->
</template>

<script>
import {
  ElMessage,
  ElLoading
} from 'element-plus'
import showDetails from '@/components/showDetails.vue';
export default {
  props:['NewData'],
  emits:['resetdata'],
  name: 'gradeActivities',
  components: {
    showDetails
  },
  data() {
    return {
		tagInputValue:'',
		inputVisible:false,
      dialogVisible:false,
      isSubmit:false,
      search: '',
      dialogTableVisible: false,
      dialogTitle: '',
      userTableData: [],
      nowPage: 1,
      pageLimit: 8,
      tableTotal: 0,
      schoolClass:'',
	  njOptions:[],
	  setClassText:'',
	  detailsData:{},
	  nowDepartment:{},
	  toolType:'',
	  toolState:'',
	  dialog:false,
	  loading:false,
	  reply_content:'',
	  userCode:JSON.parse(sessionStorage.getItem('ucode')),
	  proposalTypeList:[],
	  dialogFormVisible:false
    }
  },
  methods: {
	  handleInputConfirm(){
		  if (!this.tagInputValue) {
		  	return
		  }
		  vaildInterface("wechat/api.php","addProposalType",{
		    keyword:'addProposalType',
		    name: this.tagInputValue,
		  },"01").then((res)=>{
		  	console.log(res);
		    if (!res.success) {
		  	  ElMessage.error(res.msg);
		  	  return
		  	}
			ElMessage.success(res.msg);
			this.getProposalType();
			this.inputVisible = false;
			this.tagInputValue = '';
		  }).catch((result=>{
		    //数据返回失败
		  }));
	  },
	  showInput(){
		  this.inputVisible = true;
		  this.$nextTick ( ( ) =>{ 
			  this.$refs.InputRef.focus();
		  } )
	  },
	  handleTagClose(del){
		  vaildInterface("wechat/api.php","delProposalType",{
		    keyword:'delProposalType',
		    id: del.id,
		  },"01").then((res)=>{
		    if (!res.success) {
		  	  ElMessage.error(res.msg);
		  	  return
		  	}
			ElMessage.success(res.msg);
			this.getProposalType();
		  }).catch((result=>{
		    //数据返回失败
		  }));
	  },
	  dialogClosed(){
		  this.reply_content = '';
	  },
	  replySubmit(){
		  if (!this.reply_content) {
		  	ElMessage.error('请输入回复内容');
			return
		  }
		  this.loading = true;
      requestfaceWithHeadersfn("wechat/index.php","replyProposal",{
		    keyword:'replyProposal',
		    id: this.nowDepartment.id,
			 reply_user: this.userCode.username,
			  reply_content: this.reply_content,
		  },"01").then((res)=>{
		  	console.log(res);
		    if (!res.success) {
		  	  ElMessage.error(res.msg);
		  	  return
		  	}
			ElMessage.success(res.msg);
			this.loading = false;
			this.dialog = false;
		  }).catch((result=>{
		    //数据返回失败
		  }));
	  },
	  replyForm(){
		  this.dialog = true;
	  },
    backForm(){
      this.dialogVisible = false;
	  this.detailsData = {};
	  this.getUserTableData();
    },
    showDepartment(data){
      this.dialogVisible = true
	  this.nowDepartment = data;
		console.log(this.nowDepartment);
    },
    delDepartment(data){

    },
    handleCurrentChange(data) {
      this.nowPage = data;
      // 用户列表
      this.getUserTableData();
    },
    getUserTableData() {
      requestfaceWithHeadersfn("wechat/index.php","proposalList",{
		  keyword:'proposalList',
		  limit: this.pageLimit.toString(),
		  page: this.nowPage.toString(),
		  search:this.search,
		  state:this.toolState,
		  type:this.toolType
		},"01").then((res)=>{
			// console.log(res);
		  if (!res.success) {
			  ElMessage.error(res.msg);
			  return
			}
			this.userTableData = res.data.list;
			this.tableTotal = parseInt(res.data.total);
		}).catch((result=>{
		  //数据返回失败
		}));
    }, 
	getProposalType() {
		vaildInterface("wechat/api.php","getProposalType",{
		  keyword:'getProposalType',
		},"01").then((res)=>{
			this.proposalTypeList = res.data.list;
		}).catch((result=>{
		  //数据返回失败
		}));
    },
    selectBtn() {
      // 用户列表
      this.getUserTableData();
    },
    resetBtn() {
      this.search = '';
	  this.toolType = '';
	  this.toolState = '';
    },
  },
  mounted() {
    // 用户列表
    this.getUserTableData();
	// 下拉分类
	  this.getProposalType();
  }
}
</script>

<style scoped>
.department-table {
  width: 100%;
  height: calc(100% - 80px);
  overflow: auto;
  padding-top: 50px;
  box-sizing: border-box;
  position: relative;
}

.pagination-box {
  position: absolute;
  right: 10px;
  bottom: 20px;
}

.ctent {
  width: 100%;
  margin: 0 auto;
  height: calc(100% - 32px);
  padding: 30px;
  box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 10px;
}

.title-tool {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tool-item {
  width: 26%;
  height: 48px;
  display: flex;
  align-items: center;
  margin-right: 50px;
}

.tool-label {
  width: 80px;
  min-width: 80px;
  color: #303133;
}
.img-flex{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.demo-image__preview{
  width: 12.5%;
  box-sizing: border-box;
  padding: 0 15px 15px 0;
}
.mx-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
	margin-bottom: 0.5rem;
}
.bl-1{
	margin-left: 0.25rem;
	margin-right: 0.25rem;
}
.w-20{
	width: 80px;
}
</style>